function onFetchInit(data) {
  let form = {}
  data.forEach((e, i) => {
    form['a' + i] = e.activeName
  })
  return {
    form
  }
}

function onSubmit({ detail, form }) {
  detail.forEach((e, i) => {
    e.activeName = form['a' + i]
  })
  return {
    travelMonths: detail
  }
}

export default function() {
  return {
    type: 'form',
    url: '/base/farmTravelMonth/getList',
    edit: '/base/farmTravelMonth',
    onFetchInit,
    onSubmit,
    data: Array(12)
      .fill({})
      .map((e, i) => {
        return {
          name: i + 1 + '月副标题',
          type: 'input',
          key: 'a' + i
        }
      })
  }
}
